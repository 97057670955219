.product-picture{
    position: relative;
    display: inline-block;
    width: 100px;
}
.product-picture-a{
    position: absolute;
    top: 0px;
    right: 0px;
    font-size: 1.2rem;
    cursor: pointer;
}