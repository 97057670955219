
.home-grille{
    background-color: #fff;
    border-radius: 2px;
    overflow: hidden;
    padding: 10px;
    position: relative;
}
.home-grille-title{
    font-size: 1.1rem;
    position: absolute;
    top: 4px;
    left: 10px;
    font-weight: 700;}