@font-face {
  font-family: "iconfont"; /* Project id 923212 */
  src: url('iconfont.woff2?t=1636454222327') format('woff2'),
       url('iconfont.woff?t=1636454222327') format('woff'),
       url('iconfont.ttf?t=1636454222327') format('truetype');
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-tixian:before {
  content: "\e627";
}

.icon-shangpin:before {
  content: "\e65b";
}

.icon-tongjijisuan:before {
  content: "\e659";
}

.icon-odbc-full:before {
  content: "\e93a";
}

.icon-dingdan:before {
  content: "\e645";
}

.icon-shangcheng:before {
  content: "\e62e";
}

.icon-sousuo:before {
  content: "\e603";
}

.icon-daohang:before {
  content: "\e62b";
}

.icon-chakanshu:before {
  content: "\e67f";
}

.icon-gengduo:before {
  content: "\e69b";
}

.icon-zuire:before {
  content: "\e637";
}

