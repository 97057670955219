.login{
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content:center;
    align-items:center;
}
.logo {
    float: left;
    width: 125px;
    height: 31px;
    margin: 16px 24px 16px 0;
}