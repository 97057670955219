@import '~antd/dist/antd.css';
@import "./static/font/iconfont.css";
.iconfont{ margin-left: 1px; }
button{ cursor: pointer;}
img{border:none;}
::-webkit-scrollbar-track-piece {
    background-color:#f8f8f8;
}
::-webkit-scrollbar {
    width:3px;
}
::-webkit-scrollbar-thumb {
    background-color:#1890ff;
    background-clip:padding-box;
}
::-webkit-scrollbar-thumb:hover {
    background-color:#1890ff;
}
