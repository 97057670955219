.login{
    background-image: url("../../static/img/bluesky.jpg");
    background-position: center bottom;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content:center;
    align-items:center;
}
.login-centent{
    width: 400px;
    background: rgba(255,255,255,0.8);
    border-radius: 10px;

}
.login-form{ }
.login-button{ width: 58%; margin:10px 0px 30px 25%;}