.manage{ margin-top: 15px;}
.search-order{background-color: darkorange;
    color: #fff;}
.order-info{ padding-right: 50px; color: red; }
.outbound-data{ width: 400px; display: block; }
.outbound-data-centent{margin-top: 15px;}
.outbound-data-bottom{margin-top: 40px;}
.close-order-bottom{margin:20px 0px 0px 70%;}
.ant-form-item-buttom{
    margin-bottom: 0px;
}